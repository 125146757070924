import logo from "./GoTurtle.png"
import React, { useState, useEffect, useRef } from 'react';
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label, Table, Card
} from "reactstrap";
import TimePicker from 'react-time-picker';
import maplibregl from 'maplibre-gl';
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';
import Select from "react-select";
//import 'maplibre-gl/dist/maplibre-gl-csp-worker';
import 'maplibre-gl/dist/maplibre-gl.css';
import styled from 'styled-components';
import { CalculateRouteCommand, LocationClient, SearchPlaceIndexForPositionCommand, CalculateRouteMatrixCommand, SearchPlaceIndexForTextCommand } from '@aws-sdk/client-location';
import { withAPIKey  } from '@aws/amazon-location-utilities-auth-helper';
import axios from 'axios';
import './index.css';

maplibregl.workerClass = require('maplibre-gl/dist/maplibre-gl-csp-worker');

// Define your Maplibre-GL access token
const apiKey = 'v1.public.eyJqdGkiOiJlZmExMmJkYi01ZDRjLTRhNDYtYWU4MC1jNGYwOWI3YTdhMzcifWIxNqOZ_j457XEoB0MRcamDyi2b2EEHe-p9fs_IzsvaPcpO6Z_bSsw0kV6Pd3ZbVIZooPM57PPwPjDvpKim6NmhgihXugNyixKEAmaKg-3YnuHLnDFS-GVk0sxkFwY99TuhTZXVivuXzKtswQu8Oq-OCQ8AGN3i-YKgEm08j2SSDmg9M1qmlotQ9AE_JZxIjzoO2fDva6QrZp8RJhEAfE5aj_XoOSrJcxnSzJsBxAsXlxz8d0AnpUuvuayO6O5u5XZ9VKwcka4LwFmPWrrbK45_Fg0MWmxU9SbfT4VcX81ubf5ghRIf7jOlXMj9K5KCSp1H11qBlNkwOFlqxhX49r8.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx'; // Replace with your Amazon Location API key
const mapName = 'FlashCat'; // Replace with your map resource name
const placeIndex = 'FlashCatIndex-dev';

const sampleDistanceMatrix = [
  [0, 30.06, 36.566, 145.379, 69.402, 111.075, 18.537, 33.697, 34.818],
  [27.472, 0, 13.785, 139.284, 79.351, 100.196, 36.477, 42.804, 23.939],
  [37.658, 13.384, 0, 149.47, 89.537, 89.332, 46.663, 52.99, 11.171],
  [145.164, 142.603, 149.109, 0, 103.699, 82.276, 139.35, 116.993, 147.187],
  [68.542, 82.896, 89.402, 104.067, 0, 87.273, 61.811, 39.22, 87.48],
  [112.687, 104.829, 91.261, 82.755, 88.18, 0, 111.501, 101.403, 81.639],
  [14.731, 38.241, 44.747, 140.184, 58.039, 109.562, 0, 22.334, 42.825],
  [33.166, 45.855, 52.361, 117.696, 39.151, 100.667, 22.291, 0, 51.453],
  [35.306, 27.448, 11.052, 146.947, 87.014, 82.793, 44.311, 39.646, 0],
];

// Actual location coordinates
const locationCoordinates = [
 { longitude: -83.045833, latitude: 42.331389 }, //Detroit
 { longitude: -83.023889, latitude: 42.491944 }, //Warren
 { longitude: -83.028056, latitude: 42.579722 }, //Sterling Heights
 { longitude: -84.546667, latitude: 42.733611 }, //Lansing
 { longitude: -83.748333, latitude: 42.281389 }, //Ann Arbor
 { longitude: -83.693333, latitude: 43.018889 }, //Flint
 { longitude: -83.213333, latitude: 42.314444 }, //Dearborn
 { longitude: -83.373611, latitude: 42.397222 }, //Livonia
 { longitude: -83.143056, latitude: 42.580278 }, //Troy
  // Add coordinates for other locations
];

const containerStyle = {
  // display: 'flex',
  // flexDirection: 'row',
  // justifyContent: 'space-between',
  // alignItems: 'center',
  marginBottom: 10,
  position: 'absolute',
  right: '10px',
  width:'260px',
}
const subcontainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: -35
}


function App() {
 /*  const [optimizedPath, setOptimizedPath] = useState([]);
  const [snappedRoute, setSnappedRoute] = useState([]); */
  const [region, setRegion] = useState('us-east-1');
  const [selectDriver, setSelectDriver] = useState(null);
  const [selectRoutes, setSelectRoutes] = useState(null);
  const [displayTrue, setDisplayTrue] = useState(false);
  const [submitAlertStatus, setSubmitAlertStatus] = useState(false);
  const [drivers, setDrivers] = useState([
  //   { value: 'option1', label: 'Option 1' },
  // { value: 'option2', label: 'Option 2' },
  // { value: 'option3', label: 'Option 3' },
  ]);
  const [routes, setRoutes] = useState([
  //   { value: 'option1', label: 'Option 1' },
  // { value: 'option2', label: 'Option 2' },
  // { value: 'option3', label: 'Option 3' },
  ]);
  //const [routesData, setRoutesData] = useState([]);
  const [time, setTime] = useState('10:00');
  /* const [coordinatesArray, setCoordinatesArray] = useState([])
  const [filteredCoordinatesArray, setFilteredCoordinatesArray] = useState([])
  const [distanceMatrix, setDistanceMatrix] = useState([]) */
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [distanceSummary, setDistanceSummary] = useState({});
  const [activeOrders, setActiveOrders] = useState(0);
  const [markers, setMarkers] = useState([]);

  /* calculate route matirx */

  const data = [{ id: "DX5046329871", PostalCode: "92316", Street: "Bloomington, CA" },
  { id: "DX6481057932", PostalCode: "92376", Street: "Rialto, CA " },
  { id: "DX6501427389", PostalCode: "92504", Street: "Riverside, CA" },
  { id: "DX4091837652", PostalCode: "92336", Street: "Fontana CA" },
  { id: "DX 9207536184", PostalCode: "92882", Street: "Corona, CA" },]

const calculateCoordinates = async (data) => {

  console.log("Data Received in Calculate Coordinates",data);
 
  const authHelper = await withAPIKey(apiKey);
  const client = new LocationClient({
    region,
    ...authHelper.getLocationClientConfig(),
  });

  const coordinates = [];
  // if (flag == 0) {

  // await Promise.all(

    // Object.entries(data).map(async ([zipCode, orders]) => {
    // console.log("Orders in newly modified code: ",orders);
      await Promise.all(
        data.map(async (order) => {
          const params = {
            IndexName: placeIndex,
            Text: `${order.Street}, ${order.vendor_city}, ${order.state}, ${order.PostalCode}`,
            FilterCountries: ["USA"],
            MaxResults: 1,
          };

          try {
            const command = new SearchPlaceIndexForTextCommand(params);
            const response = await client.send(command);
            const result = response.Results;

            if (result) {
              coordinates.push({
                orderId: order.id,
                customerName:order.name,
                phone:order.phone,
                street: order.Street,
                zipCode: order.PostalCode,
                coordinates: result[0].Place.Geometry.Point,
              });
            } else {
              console.error(`Location not found for ${order.Street}, ${order.PostalCode}`);
            }
          } catch (error) {
            console.error(`Error calculating coordinates: ${error.message}`);
          }

        })
      );

    // })
    // );

/* The commented code used for getting the coordinates based on zip codes */
    
/*   await Promise.all(
    data.map(async(item)=> {

    console.log("Each Item in Array: ", item)
      const params = {
        IndexName: placeIndex,
        Text: item,
        FilterCountries: ["USA"],
        MaxResults: 1
        // FilterCountries: "INDIA"
      };

      try {

        const command = new SearchPlaceIndexForTextCommand(params)
        const response = await client.send(command);
        const result = response.Results;


        if (result) {

          coordinates.push({ zipCode: item, coordinates: result[0].Place.Geometry.Point });
        } else {
          console.error(`Location not found for ${item}`);
        }        } catch (error) {
        console.error(`Error calculating coordinates: ${error.message}`);
      }
    })); */
  //   flag = 1;
  // }
  console.log("Coordinates Array: ",coordinates);
  
  //setCoordinatesArray(coordinates);
  return coordinates
}

const filterCoordinates = (coordinates) => {
  return coordinates.map(item => item.coordinates);
}

const routecalculationMatrix = async (coordinates) => {
  const authHelper = await withAPIKey(apiKey);
  const clients = new LocationClient({
    region,
    ...authHelper.getLocationClientConfig(),
  });
  
  console.log("Coordinates Array inside RoutecalulateMatrix",coordinates);
  let distancesArray = [];
  
    const filteredCoordinates = filterCoordinates(coordinates);
    //console.log('Delivery Coordinates: ',coordinatesDelivery);
    console.log('Filtered Coordinates: ',filteredCoordinates);
    // const firstPlaceData = startEnd[0];
    // const secondPlaceData = startEnd[1];
    
    const input = {
      CalculatorName: "FlashcatCalculator",
      DeparturePositions:filteredCoordinates,
      DestinationPositions: filteredCoordinates,
      TravelMode: 'Truck',
      DistanceUnit:'Miles', 
      IncludeLegGeometry: true

    };
    const command = new CalculateRouteMatrixCommand(input);
    
    const response = await clients.send(command);
    console.log('Calculated Route Matrix: ',response);
    
    distancesArray = response.RouteMatrix.map(row =>
      row.map(data => data.Distance)
    );
  
    // Log the extracted distances
    console.log("Distances Array: ", distancesArray);
    /* setDistanceMatrix(distancesArray);
    const updatedFilteredCoordinates = filteredCoordinates.map(([longitude, latitude]) => ({
          longitude,
          latitude
      }));
 */
    return distancesArray;
}

function calculateTSP(matrix) {
  const numLocations = matrix.length;
  const visited = new Array(numLocations).fill(false);
  const path = [0]; // Start from the first location
  visited[0] = true;

  while (path.length < numLocations) {
      let minDistance = Number.MAX_VALUE;
      let nearestNeighbor = -1;

      for (let i = 0; i < numLocations; i++) {
          if (!visited[i] && matrix[path[path.length - 1]][i] < minDistance) {
              minDistance = matrix[path[path.length - 1]][i];
              nearestNeighbor = i;
          }
      }

      if (nearestNeighbor !== -1) {
          path.push(nearestNeighbor);
          visited[nearestNeighbor] = true;
      }
  }

  return path;
}

async function calculateSnappedRoute(locations, clients) {
  //console.log("Locations: ", locations)
  const snappedRoute = [];

  for (let i = 0; i < locations.length - 1; i++) {
    const startLocation = locations[i];
    const endLocation = locations[i + 1];

    const command = new CalculateRouteCommand({
      CalculatorName: "FlashcatCalculator",
      DeparturePosition: [startLocation.longitude, startLocation.latitude],
      DestinationPosition: [endLocation.longitude, endLocation.latitude],
      TravelMode: 'Truck',
      IncludeLegGeometry: true
    });

    const response = await clients.send(command);

    console.log("Response",response)

    let hours = Math.floor(response.Summary.DurationSeconds / 3600);
      let minutes = Math.floor((response.Summary.DurationSeconds % 3600) / 60);
      let seconds = response.Summary.DurationSeconds % 60;
      let duration = hours + "h  " + minutes + "m  " + seconds + "s  "
  
    console.log("Duration: ",duration)
    const distanceStatistics = {distance:response.Summary.Distance,distanceUnit:response.Summary.DistanceUnit,duration:duration};
    console.log("Distance Statistics: ",distanceStatistics)
    setDistanceSummary(distanceStatistics);

    if (response.Legs && response.Legs.length > 0) {
      for (const leg of response.Legs) {
        if (leg.Geometry && leg.Geometry.LineString) {
          // Add the coordinates from the leg's geometry to the snapped route
          snappedRoute.push(...leg.Geometry.LineString);
        }
        if (leg.Steps && leg.Steps.length > 0) {
          for (const step of leg.Steps) {
            if (step.Geometry && step.Geometry.LineString) {
              // Add the coordinates from the step's geometry to the snapped route
              snappedRoute.push(...step.Geometry.LineString);
            }
          }
        }
      }
    }
  }

  console.log("Snapped Route: ", snappedRoute)
  return snappedRoute;
}

  useEffect(() => {
    const fetchData = async () => {
    let formData = new FormData();
    formData.append('facility_id', 6);
    formData.append('user_id', 2);
    formData.append('user_type', 1);

    let driverFormData = new FormData();
    driverFormData.append('facility_id', 6);

  try{
        Promise.allSettled([
          axios.post('https://goturtle-api.gettridant.com/webservice/transportation/get-all-routes-base', formData),
          axios.post('https://goturtle-api.gettridant.com/webservice/transportation/get-all-drivers', driverFormData)
        ])
        .then((results)=>{
          console.log(results[0])
          const routesBase = results[0].value.data.routes.map(({ id, name }) => ({ value: id, label: name }));
          console.log("Routes:",routesBase)
          setRoutes(routesBase);
          const drivers = results[1].value.data.drivers.map(({ id, name }) => ({ value: id, label: name }));
          console.log("Drivers:",drivers)
          setDrivers(drivers);
        })

    }catch(e) {
      console.log("Addition failed , Error ", e);
    };
  }
  fetchData();
    
  },[]);

  /* useEffect(() => {
    console.log("Distance Matrix after Route Matrix: ",distanceMatrix);
    console.log("Coordinates array to calulate TSP: ",filteredCoordinatesArray);
    const tspSolution = calculateTSP(distanceMatrix);
    const closedPath = [...tspSolution, tspSolution[0]];

    (async () => {
      const authHelperInstance = await withAPIKey(apiKey);
      const clients = new LocationClient({
        region,
        ...authHelperInstance.getLocationClientConfig(),
      });

      if(filteredCoordinatesArray.length > 0)
      {
      const snappedRoute = await calculateSnappedRoute(
        //closedPath.map((index) => locationCoordinates[index]),
        closedPath.map((index) => filteredCoordinatesArray[index]),
        clients
      );

      setSnappedRoute(snappedRoute);
      }
      
    })();
  }, [filteredCoordinatesArray,distanceMatrix]); */

 /*  useEffect(() => {
    if (snappedRoute.length > 0) {
      const map = new maplibregl.Map({
        container: 'map',
        style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
        center: [snappedRoute[0][0], snappedRoute[0][1]],
        zoom: 7,
      });

        map.on('load', () => {
          map.addLayer({
            id: 'route',
            type: 'line',
            source: {
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: snappedRoute,
                },
              },
            },
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#888',
              'line-width': 8,
            },
          });

            //locationCoordinates.forEach((coordinate, index) => {
           
              filteredCoordinatesArray?.forEach((coordinate, index) => {
              new maplibregl.Marker({ draggable: false })
                .setLngLat([coordinate.longitude, coordinate.latitude])
                .setPopup(new maplibregl.Popup().setHTML(`Location ${index}`))
                .addTo(map);
            });
        

          const bounds = snappedRoute.reduce(
            (bounds, coord) => bounds.extend(coord),
            new maplibregl.LngLatBounds().extend(snappedRoute[0])
          );
          map.fitBounds(bounds, { padding: 50 });
        });
      }
    }, [snappedRoute, region]);
 */
    const customStyles = {
      control: (base) => ({
        ...base,
        width:'100% !important',
        '&:hover': { borderColor: 'black' },
      }),
    };

    const StyledTimePicker = styled(TimePicker)`
  input {
    width: 85px !important;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right:5px;
  }
  span {
    display: none;
  }
  button {
    display: none;
  }
  select {
    padding: 3px 5px;
    border-radius: 4px;
  }

  .react-time-picker__clock {
    color: #007bff;
  }
  // .react-time-picker__inputGroup__leadingZero {
  //   display: none;
  // }
`;
    const StyledSelect = styled(Select)`
  input {
    // width: 85px !important;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right:5px;
  }
  // div {
  //   width: auto;
  // }

  div .select__control css-3mboii-control {
    width: 200% !important;
  }
`;

  const mapRef = useRef(null);
  useEffect(() => {
    // Initialize the map when the component mounts
    const map = new maplibregl.Map({
      container: 'map',
      style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
      center: [-123.1187, 49.2819],
      zoom: 7,
    }); 
    
    mapRef.current = map;
    // Optionally, you can perform other map-related operations here
    // such as adding layers, markers, event listeners, etc.

    return () => {
      // Clean up resources if needed when the component unmounts
      map.remove();
    };
  }, []); // The empty dependency array ensures that this effect runs only once when the component mounts

  const removeMarkers = () => {
    const existingMarkers = mapRef.current.getLayer('markers');
    console.log("Existing Markers: ",existingMarkers)
    if (existingMarkers) {
      mapRef.current.removeLayer('markers').removeSource('markers');
    }
  };

 // Function to update the map's center
  const updateMapCenter = (mapRef, longitude, latitude) => {
    mapRef.current.setCenter([longitude, latitude]);
  };

  const updateRouteLayer = (mapRef, snappedRoute) => {
    // Check if the 'route' layer already exists
    // const existingLayer = mapRef.current.getLayer('route');
  
    // if (existingLayer) {
    //   // If the layer exists, update its data
    //   mapRef.current.getSource('route').setData({
    //     type: 'Feature',
    //     properties: {},
    //     geometry: {
    //       type: 'LineString',
    //       coordinates: snappedRoute,
    //     },
    //   });
    // } else {
    //   //If the layer doesn't exist, add a new layer
    //   mapRef.current.addLayer({
    //     id: 'route',
    //     type: 'line',
    //     source: {
    //       type: 'geojson',
    //       data: {
    //         type: 'Feature',
    //         properties: {},
    //         geometry: {
    //           type: 'LineString',
    //           coordinates: snappedRoute,
    //         },
    //       },
    //     },
    //     layout: {
    //       'line-join': 'round',
    //       'line-cap': 'round',
    //     },
    //     paint: {
    //       'line-color': '#888',
    //       'line-width': 8,
    //     },
    //   });
    // }
    // Check if the 'route' layer already exists
  const existingLayer = mapRef.current.getLayer('route');

  if (existingLayer) {
    // If the layer exists, remove it
    mapRef.current.removeLayer('route').removeSource('route');
  }

  // Add a new layer
  mapRef.current.addLayer({
    id: 'route',
    type: 'line',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: snappedRoute,
        },
      },
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#888',
      'line-width': 8,
    },
  });
  };  

    const handleChange = (selectedOption) => {
      setSelectDriver(selectedOption );
    };
    const handleChangeRoute = (selectedOption) => {
      // Set loading state to true
      setLoading(true);
      setLoadingMessage('Loading route, please wait...');

      updateMapCenter(mapRef, -123.1187, 49.2819);

      console.log("selected route: ",selectedOption);

      const routeBaseDetail = new FormData();
      routeBaseDetail.append('facility_id', 6);
      routeBaseDetail.append('route_id', selectedOption.value);
      axios.post('https://goturtle-api.gettridant.com/webservice/transportation/view-route-base-detail', routeBaseDetail).then((res) => {
      console.log("View Route Base API response: ", res)
      const zipCodes = res.data.route_base.zip_codes.split(',').map((code) => code.trim());

      // Styled component for popup content
      const styledPopupContent = styled.div`
      max-height: 200px; /* Set your desired max height */
      overflow-y: auto;
      `;

      const generatePopupContent = (orders) => {
        console.log("Orders in generatePopupContent: ",orders);
        return orders.map((order, index) => {
          return `
            <div>
              <p>Order ${/* index + 1 */order.id}</p>
              <p>Customer: ${order.customer}</p>
              <p>Address: ${order.vendor_address}, ${order.vendor_city}, ${order.state} ${order.PostalCode}</p>
              <p>Total: ${order.total}</p>
              <!-- Add more order details as needed -->
            </div>
          `;
        }).join('');
      };

    if(zipCodes)
    {
      // Group customerOrders based on zip codes
      const grouped = {};
      zipCodes.forEach((zip) => {
        grouped[zip] = res.data.order_data.filter(
          (order) => order.PostalCode.trim() === zip
        );
      });
      console.log("Grouped Orders: ",grouped);

      const orderTotals = Object.keys(grouped).reduce((totals, zipCode) => {
        const totalOrders = grouped[zipCode].length;
        totals[zipCode] = totalOrders;
        return totals;
      }, {});
    
      // Calculate the overall total number of orders
      const overallTotal = Object.values(orderTotals).reduce((sum, total) => sum + total, 0);

      setActiveOrders(overallTotal);

      (async () => {
          let uniqZipCodes = [... new Set(zipCodes)];
          console.log("Unique Zip Codes: ",uniqZipCodes);
          const coordinates= await calculateCoordinates(res.data.order_data);
          console.log("Coordinates: ",coordinates);

          const uniqueCoordsSet = new Set();
          const uniqueCoordsArray = coordinates.filter((item) => {
            const coordsKey = item.coordinates.toString();
            if (!uniqueCoordsSet.has(coordsKey)) {
              uniqueCoordsSet.add(coordsKey);
              return true;
            }
            return false;
          });

          console.log("Unique Coordinates: ",uniqueCoordsArray);
 
          if(coordinates)
          {
            const distanceMatrix = await routecalculationMatrix(uniqueCoordsArray);
            console.log("Distances:", distanceMatrix);

            if(distanceMatrix)
            {
              const tspSolution = calculateTSP(distanceMatrix);
              const closedPath = [...tspSolution, tspSolution[0]];
              
              console.log("Closed Path: ",closedPath);

              const authHelperInstance = await withAPIKey(apiKey);
              const clients = new LocationClient({
                region,
                ...authHelperInstance.getLocationClientConfig(),
              });

              if(coordinates.length > 0)
              {
                  const formattedCoordinates = filterCoordinates(uniqueCoordsArray).map(([longitude, latitude]) => ({
                    longitude,
                    latitude
                  }));

                  console.log("Formatted Coordinates: ",formattedCoordinates);

                 const snappedRoute = await calculateSnappedRoute(
                  //closedPath.map((index) => locationCoordinates[index]),
                  closedPath.map((index) => formattedCoordinates[index]),
                  clients
                ); 
                
                
                if (snappedRoute.length > 0) {

                  // Clear existing markers
                  console.log("Existing Markers:",markers);

                  markers.forEach(marker => marker.remove());
                  markers.length = 0; // Clear the array

                  
                 /* const map = new maplibregl.Map({
                    container: 'map',
                    style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
                    center: [snappedRoute[0][0], snappedRoute[0][1]],
                    zoom: 7,
                  }); 

                    map.on('load', () => {
                      map.addLayer({
                        id: 'route',
                        type: 'line',
                        source: {
                          type: 'geojson',
                          data: {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                              type: 'LineString',
                              coordinates: snappedRoute,
                            },
                          },
                        },
                        layout: {
                          'line-join': 'round',
                          'line-cap': 'round',
                        },
                        paint: {
                          'line-color': '#888',
                          'line-width': 8,
                        },
                      }); */

                    // Check if the map is already initialized
                    if (mapRef.current && !mapRef.current.loaded()) {
                      // If not initialized, wait for the 'load' event before updating layers and center
                      mapRef.current.on('load', () => {
                        updateMapCenter(mapRef, snappedRoute[0][0], snappedRoute[0][1]);
                        updateRouteLayer(mapRef, snappedRoute);
                        // Add or update other layers and data as needed
                        // ...
                      });
                    } else {
                      // If already initialized, update layers and center immediately
                      updateMapCenter(mapRef, snappedRoute[0][0], snappedRoute[0][1]);
                      updateRouteLayer(mapRef, snappedRoute);
                      // Add or update other layers and data as needed
                      // ...
                    }
            
                        //locationCoordinates.forEach((coordinate, index) => {
                       
                        /* formattedCoordinates.forEach((coordinate, index) => {
                          new maplibregl.Marker({ draggable: false })
                            .setLngLat([coordinate.longitude, coordinate.latitude])
                            .setPopup(new maplibregl.Popup().setHTML(`Location ${index}`))
                            .addTo(mapRef.current);
                        }); */

                        const groupedByCoordinates = {};
                        coordinates.forEach((item) => {
                          const key = item.coordinates.join(',');
                          if (!groupedByCoordinates[key]) {
                              groupedByCoordinates[key] = [];
                          }
                          groupedByCoordinates[key].push(item);
                      });
                      console.log("Grouped By Coordinates: ",groupedByCoordinates);
                    
                        // // Iterate through coordinates
                        // uniqueCoordsArray.forEach(async ({ zipCode, coordinates }) => {
                        //   console.log("Zip Code and Cooridnates inside Marker Loop: ",zipCode+","+coordinates)
                        //   // Ensure that the asynchronous operations inside the IIFE complete before creating the marker

                        //   // Create a marker for each coordinate
                        //   const popup = new maplibregl.Popup();
                        //   new maplibregl.Marker({ draggable: false })
                        //     .setLngLat(coordinates)
                        //     .setPopup(popup.setHTML(`<div style='max-height:200px;overflow-y:auto;'>`+generatePopupContent(grouped[zipCode.trim()])+`</div>`))
                        //     .addTo(mapRef.current);
                        //     return null;
                        // });
                        console.log("Current Reference: ",mapRef.current)

                        const markerPromises = Object.values(groupedByCoordinates).map(async (group) => {
                          const { coordinates } = group[0];
                          console.log("Coordinates inside Marker Loop: ",coordinates)

                          console.log("Each Group: ",group);
    
                        // Ensure that the asynchronous operations inside the IIFE complete before creating the marker
                        // Create a marker for each coordinate
                        const popup = new maplibregl.Popup();
                        let htmlContent = ``;
                           group.forEach(({ orderId, customerName, street, zipCode }) => {
                            // popup.setHTML(
                              htmlContent = htmlContent +
                                     `
                                     <p>Order ${/* index + 1 */orderId}</p>
                                     <p>Customer: ${customerName}</p>
                                     <p>Address: ${street}, ${zipCode}</p>
                                     <!-- Add more order details as needed -->
                                   `
                            // );
                        });
                        popup.setHTML(`<div style='max-height:100px;overflow-y:auto;'><div>`+htmlContent+`<p>Total: ${group.length}</p></div></div>`)

                        let marker = new maplibregl.Marker({ draggable: false })
                            marker.setLngLat(coordinates)
                            .setPopup(popup)
                            .addTo(mapRef.current);

                          // Update markers using setMarkers to persist it across re-renders
                          setMarkers(prevMarkers => [...prevMarkers, marker]);

                           // Return a resolved promise (or any other value) to ensure that the map function creates an array of promises
                          return Promise.resolve();

                      });

                       // Wait for all marker promises to resolve
                       await Promise.all(markerPromises);

                       const bounds = snappedRoute.reduce(
                        (bounds, coord) => bounds.extend(coord),
                        new maplibregl.LngLatBounds().extend(snappedRoute[0])
                      );
                      mapRef.current.fitBounds(bounds, { padding: 50 }); 
                    //});
                    //Reset loading state to false once everything is loaded
                    setLoading(false);
                    setLoadingMessage('Route Loaded Successfully');
                  }
                  else
                  {
                    setLoading(false);
                    setLoadingMessage('Failed to load route because of the insufficient data');
                  }
              }
              else
              {
                setLoading(false);
                setLoadingMessage('Failed to load route because of missing coordinates');
              }
            
            }
            else
            {
              setLoading(false);
              setLoadingMessage('Failed to load route because of failed optimization');
            }
        }
        else
        {
          setLoading(false);
          setLoadingMessage('Failed to load route because of missing coordinates');
        }

      })()
      
      
    }
    else
    {
      setLoading(false);
      setLoadingMessage('');
    }

    });
    console.log("Markers at the end of the funciton: ",markers);
    setSelectRoutes(selectedOption );
  }

  console.log("Markers: ",markers);

    const handleTimeChange = (newTime) => {
      setTime(newTime);
    };

    const handleClose = () => {
      setLoading(false);
      setLoadingMessage('');
    };

    const handleSubmit = () => {
      setDisplayTrue(!displayTrue);
      setSubmitAlertStatus(true);
    }

    const submitClose = () => {
      setSubmitAlertStatus(false);
    };

    return (
      <div>
        {loading && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', textAlign: 'center', zIndex: 9999, transition: 'opacity 0.3s ease' }}>
        <p style={{ fontSize: '24px', fontWeight: 'bold', margin: '0 0 20px' }}>{loadingMessage}</p>
        <button style={{ fontSize: '18px', padding: '10px 20px', background: '#4caf50', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }} onClick={handleClose}>Close</button>

          {/* <div style={{ width: '200px', height: '10px', backgroundColor: '#ccc', borderRadius: '5px' }}>
            <div style={{ content: '', display: 'block', width: '50%', height: '100%', backgroundColor: '#4caf50', borderRadius: '5px' }}></div>
          </div> */}
        </div>
      )}

      {submitAlertStatus && (
            <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', textAlign: 'center', zIndex: 9999, transition: 'opacity 0.3s ease' }}>
            <p style={{ fontSize: '24px', fontWeight: 'bold', margin: '0 0 20px' }}>{ "Driver Assigned Successfully" }</p>
            <button style={{ fontSize: '18px', padding: '10px 20px', background: '#4caf50', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }} onClick={submitClose}>Close</button>

              {/* <div style={{ width: '200px', height: '10px', backgroundColor: '#ccc', borderRadius: '5px' }}>
                <div style={{ content: '', display: 'block', width: '50%', height: '100%', backgroundColor: '#4caf50', borderRadius: '5px' }}></div>
              </div> */}
            </div>
        )}

        <div id="map" style={{ height: '100vh', width: '100%' }} />
        {/* <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          width:'260px',
          backgroundColor: 'rgba(255, 255, 255, 1)',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
        }}
      > */}
          <div style={{...containerStyle,top:'30px'}}>
            <StyledSelect
              name="driver_id"
              id="driver_id"
              placeholder="Choose Route"
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              value={selectRoutes}
              options={routes}
              onChange={handleChangeRoute}
              styles={customStyles}
            />
          </div>
          <div style={{...containerStyle,top:'75px'}}>
              {/* <div>
                <h4 style={{ fontSize: '15px',marginRight:5 }}>Choose Time</h4>
              </div>
              <p>{time}</p> */}
                <StyledTimePicker
                  onChange={handleTimeChange}
                  value={time}
                />
            </div>
              <div style={{...containerStyle,top:'110px'}}>
                <Select
                  name="driver_id"
                  id="driver_id"
                  placeholder="Choose Driver"
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  value={selectDriver}
                  options={drivers}
                  onChange={handleChange}
                  styles={customStyles}
                />
              </div>
              <div style={{...containerStyle,top:'150px',width:'260px'}}>
              <Button
                style={{
                  borderRadius: '5px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingTop: '7px',
                  paddingBottom: '7px',
                  marginTop: '10px',
                  width: '100%',
                  background: '#f16209',
                  border: 'red',
                  color: '#fff',
                  cursor:'pointer'
                }}
                type="submit"
                color="primary"
                disable={displayTrue}
                onClick={/* ()=>setDisplayTrue(!displayTrue) */ handleSubmit}
              >
                Submit
              </Button>
            </div>
      <div style={{ position: 'absolute', top: '3px', left: '10px', zIndex: '500' }}>
        <img src={logo} alt="Company Logo" width={window.innerWidth < 380 ? '35px' : '25%'} height="auto" />
      </div>
      {/* </div> */}
        {displayTrue && <div
        style={{...(window.innerWidth < 546 ? {
          top:'200px',
          right: '10px',
        } : {
          top:'30px',
          left: '10px',
        }),
          position: 'absolute',
          width:'240px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '0px 10px 20px',
          borderRadius: '5px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
          paddingBottom:'20px'
        }}
      >
              <div style={subcontainerStyle}>
                <h4 style={{ fontSize: '15px',marginRight:5 }}>Active Order</h4>
                <h5 style={{ fontSize: '15px',marginRight:5 }}>{activeOrders}</h5>
              </div>
              <div style={subcontainerStyle}>
                <h4 style={{ fontSize: '15px',marginRight:5 }}>Distance</h4>
                <h5 style={{ fontSize: '15px',marginRight:5 }}>{distanceSummary.distance+''+distanceSummary.distanceUnit}</h5>
              </div>
              <div style={subcontainerStyle}>
                <h4 style={{ fontSize: '15px',marginRight:5 }}>Traffic</h4>
                <h5 style={{ fontSize: '15px',marginRight:5 }}>Normal</h5>
              </div>
              <div style={subcontainerStyle}>
              <h4 style={{ fontSize: '15px',marginRight:5 }}>Vehicle Type</h4>
              <h5 style={{ fontSize: '15px',marginRight:5 }}>Truck</h5>
              </div>
      </div>}
      {/* <div>
        {snappedRoute.length > 0 ? (
          <div id="map" style={{ height: '100vh', width: '100%' }} />
        ) : (
          <p>Loading map...</p>
        )}
      </div> */}
      </div>
    );
}

export default App;