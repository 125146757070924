// App.js
import React from 'react';
import TSPSolver from './TSPSolver';

function App() {
  const distanceMatrix = [[{"Distance":0,"DurationSeconds":0},{"Distance":30.06,"DurationSeconds":1233},{"Distance":36.566,"DurationSeconds":1661},{"Distance":145.379,"DurationSeconds":5052},{"Distance":69.402,"DurationSeconds":2512},{"Distance":111.075,"DurationSeconds":3902},{"Distance":18.537,"DurationSeconds":1019},{"Distance":33.697,"DurationSeconds":1342},{"Distance":34.818,"DurationSeconds":1479}],
  [{"Distance":27.472,"DurationSeconds":1105},{"Distance":0,"DurationSeconds":0},{"Distance":13.785,"DurationSeconds":730},{"Distance":139.284,"DurationSeconds":4564},{"Distance":79.351,"DurationSeconds":2682},{"Distance":100.196,"DurationSeconds":3396},{"Distance":36.477,"DurationSeconds":1560},{"Distance":42.804,"DurationSeconds":1753},{"Distance":23.939,"DurationSeconds":973}],
  [{"Distance":37.658,"DurationSeconds":1745},{"Distance":13.384,"DurationSeconds":796},{"Distance":0,"DurationSeconds":0},{"Distance":149.47,"DurationSeconds":5204},{"Distance":89.537,"DurationSeconds":3322},{"Distance":89.332,"DurationSeconds":3244},{"Distance":46.663,"DurationSeconds":2200},{"Distance":52.99,"DurationSeconds":2393},{"Distance":11.171,"DurationSeconds":797}],
  [{"Distance":145.164,"DurationSeconds":5053},{"Distance":142.603,"DurationSeconds":4719},{"Distance":149.109,"DurationSeconds":5147},{"Distance":0,"DurationSeconds":0},{"Distance":103.699,"DurationSeconds":3523},{"Distance":82.276,"DurationSeconds":3044},{"Distance":139.35,"DurationSeconds":4805},{"Distance":116.993,"DurationSeconds":3975},{"Distance":147.187,"DurationSeconds":5002}],
  [{"Distance":68.542,"DurationSeconds":2464},{"Distance":82.896,"DurationSeconds":2860},{"Distance":89.402,"DurationSeconds":3288},{"Distance":104.067,"DurationSeconds":3561},{"Distance":0,"DurationSeconds":0},{"Distance":87.273,"DurationSeconds":3127},{"Distance":61.811,"DurationSeconds":2267},{"Distance":39.22,"DurationSeconds":1452},{"Distance":87.48,"DurationSeconds":3143}],
  [{"Distance":112.687,"DurationSeconds":3929},{"Distance":104.829,"DurationSeconds":3579},{"Distance":91.261,"DurationSeconds":3244},{"Distance":82.755,"DurationSeconds":3056},{"Distance":88.18,"DurationSeconds":3165},{"Distance":0,"DurationSeconds":0},{"Distance":111.501,"DurationSeconds":4291},{"Distance":101.403,"DurationSeconds":3643},{"Distance":81.639,"DurationSeconds":2907}],
  [{"Distance":14.731,"DurationSeconds":1135},{"Distance":38.241,"DurationSeconds":1639},{"Distance":44.747,"DurationSeconds":2067},{"Distance":140.184,"DurationSeconds":4757},{"Distance":58.039,"DurationSeconds":2142},{"Distance":109.562,"DurationSeconds":4135},{"Distance":0,"DurationSeconds":0},{"Distance":22.334,"DurationSeconds":972},{"Distance":42.825,"DurationSeconds":1922}],
  [{"Distance":33.166,"DurationSeconds":1330},{"Distance":45.855,"DurationSeconds":1941},{"Distance":52.361,"DurationSeconds":2369},{"Distance":117.696,"DurationSeconds":4039},{"Distance":39.151,"DurationSeconds":1424},{"Distance":100.667,"DurationSeconds":3626},{"Distance":22.291,"DurationSeconds":1079},{"Distance":0,"DurationSeconds":0},{"Distance":51.453,"DurationSeconds":2141}],
  [{"Distance":35.306,"DurationSeconds":1539},{"Distance":27.448,"DurationSeconds":1189},{"Distance":11.052,"DurationSeconds":829},{"Distance":146.947,"DurationSeconds":5033},{"Distance":87.014,"DurationSeconds":3151},{"Distance":82.793,"DurationSeconds":2946},{"Distance":44.311,"DurationSeconds":1994},{"Distance":39.646,"DurationSeconds":2245},{"Distance":0,"DurationSeconds":0}]]

  return (
    <div className="App">
      <TSPSolver />
    </div>
  );
}

export default App;
